import { Component, NgZone, OnInit } from '@angular/core';
import { ErrorMatcher } from 'src/app/shared/error.matcher';
import { FormControl, Validators } from '@angular/forms';
import { AuthService } from 'src/app/shared/auth.service';
import { Router } from '@angular/router';
import { Md5 } from 'ts-md5/dist/md5';
import { Meta } from '@angular/platform-browser';
import { AssignmentService } from '../../shared/assignment.service';
import { Subscription } from 'rxjs';
import { ActivityService } from '../../shared/activity.service';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
    loginFaild: boolean = false;
    verify: boolean = false;

    emailFormControl = new FormControl('', [
        Validators.required
    ]);
    passwordFormControl = new FormControl('', [
        Validators.required
    ]);

    matcher = new ErrorMatcher();
    protected subscription: Subscription;

    userCred: any;

    loaderStatus: boolean = false;

    signed: boolean = false;
    user: any;

    quizzes = {
        "1-1": "zone1",
        "1-2": "zone2",
        "1-3": "zone3",
        "1-4": "zone4",
        "2-1": "zone5",
        "2-2": "zone6",
        "2-3": "zone7",
        "3-1": "africa-1",
        "3-2": "africa-2",
        "3-3": "africa-3",
        "4-1": "south-america-1",
        "4-2": "south-america-2",
        "4-3": "south-america-3",
    }

    constructor(
        private auth: AuthService,
        private activityService: ActivityService,
        private router: Router,
        private assignmentService: AssignmentService,
        private meta: Meta,
        private zone: NgZone
    ) {
        this.meta.updateTag({ name: 'viewport', content: 'width=device-width, initial-scale=1.0' });
    }

    ngOnInit() {
        this.signed = false;
        var userCredentional = localStorage.getItem("cfal");

        this.auth.logout();
        let today = new Date();
        today.setMonth(today.getMonth() + 12);

        if (userCredentional) {
            this.userCred = JSON.parse(userCredentional);

            if (!this.userCred.email) {
                console.log("USER", this.userCred);
                this.login(true);
            }
        }

        const urlParams = new URLSearchParams(window.location.search);
        const mobileAppKey = 'mobile-app';
        const qrCodeID = 'qrmode';

        const varMobileApp = urlParams.get(mobileAppKey);
        const qrSignInMode = urlParams.get(qrCodeID);

        if (varMobileApp) {
            setTimeout(() => {
                if (varMobileApp == '1') {
                    localStorage.setItem(mobileAppKey, 'true');
                } else {
                    localStorage.removeItem(mobileAppKey);
                }
            }, 1000);
        } else if (qrSignInMode) {
            this.loaderStatus = true;
            this.loginFaild = false;
            this.verify = false;

            this.loginViaQRCode(qrSignInMode);
        }
    }

    appendScriptToBody(email) {
        if (email) {
            const s = document.createElement("script");
            s.setAttribute('id', 'profitwell-js');
            s.setAttribute('data-pw-auth', 'ddef438d0364d959a508bc1cc2c4d5c1');
            s.setAttribute('type', 'text/javascript');
            s.innerHTML = "(function(i,s,o,g,r,a,m){i[o]=i[o]||function(){(i[o].q=i[o].q||[]).push(arguments)}; a=s.createElement(g);m=s.getElementsByTagName(g)[0];a.async=1;a.src=r+'?auth='+s.getElementById(o+'-js').getAttribute('data-pw-auth');m.parentNode.insertBefore(a,m);})(window,document,'profitwell','script','https://public.profitwell.com/js/profitwell.js');profitwell('start', { 'user_email': '" + email + "' });";
            document.body.appendChild(s);
        }
    }

    async generateAffiliateId(data): Promise<any> {
        var result = await this.activityService.generateAID(data).toPromise();
        return result.ID;
    }

    async loginViaGoogle() {
        this.loaderStatus = true;
        this.loginFaild = false;
        this.verify = false;
        var googleRes = await this.auth.googleSignin();

        if (googleRes.user) {
            let userStr = JSON.stringify(googleRes.user);
            let userObj = JSON.parse(userStr);
            localStorage.setItem('currentUser', userStr);

            var parentData = await this.activityService.getSelfData({ token: userObj.stsTokenManager.accessToken }).toPromise();
            var adminData = await this.activityService.getSelfData({ collectionID: "adminUserData", token: userObj.stsTokenManager.accessToken }).toPromise();

            if (!parentData.result && !adminData.result) {
                var parts = googleRes.user.displayName.split(" ");
                var affiliateId = await this.generateAffiliateId({ token: userObj.stsTokenManager.accessToken });

                let value = {
                    firstName: parts[0],
                    lastName: googleRes.user.displayName.replace(parts[0], ""),
                    email: googleRes.user.email,
                    country: "Undefined",
                    childs: [],
                    regDate: new Date().getTime(),
                    affiliateInfo: { affiliateId: affiliateId ? affiliateId : 0, clicks: 0, registrations: 0, freeMonthsEarned: 0 }
                }

                await this.activityService.createSelfData({ token: userObj.stsTokenManager.accessToken, parent: value }).toPromise();

                await this.sleep(3000);

                this.loaderStatus = false;
                this.router.navigate(['/cabinet']);

            } else if (adminData.result) {
                adminData = adminData.result;
                adminData.id = googleRes.user.uid;

                if (adminData['role'] == "super_administrator") {
                    localStorage.setItem('superAdmin_accessToken', userObj.stsTokenManager.accessToken);
                    localStorage.setItem('superAdmin_expirationTime', userObj.stsTokenManager.expirationTime);
                    localStorage.setItem('superAdmin_userData', JSON.stringify({ ...adminData, uid: adminData.id }));

                    await this.activityService.setSuperAdminAT({ token: userObj.stsTokenManager.accessToken }).toPromise();

                    this.router.navigate(['/admin/super/dashboard']);
                } else {
                    localStorage.setItem('quranera_admin_accessToken', userObj.stsTokenManager.accessToken);
                    localStorage.setItem('quranera_admin_expirationTime', userObj.stsTokenManager.expirationTime);
                    localStorage.setItem('quranera_admin_userData', JSON.stringify({ ...adminData, uid: adminData.id }));
                    this.router.navigate(['/admin']);
                }
            } else {
                this.loaderStatus = false;
                this.router.navigate(['/admin/cabinet']);
            }
        } else {
            localStorage.removeItem('currentUser');
            this.loaderStatus = false;
        }
    }

    async loginViaApple() {
        this.loaderStatus = true;
        this.loginFaild = false;
        this.verify = false;
        var googleRes = await this.auth.appleSignin();

        if (googleRes.user) {
            let userStr = JSON.stringify(googleRes.user);
            let userObj = JSON.parse(userStr);
            localStorage.setItem('currentUser', userStr);

            var parentData = await this.activityService.getSelfData({ token: userObj.stsTokenManager.accessToken }).toPromise();
            var adminData = await this.activityService.getSelfData({ collectionID: "adminUserData", token: userObj.stsTokenManager.accessToken }).toPromise();

            if (!parentData.result && !adminData.result) {
                var parts = googleRes.user.displayName.split(" ");
                var affiliateId = await this.generateAffiliateId({ token: userObj.stsTokenManager.accessToken });

                let value = {
                    firstName: parts[0],
                    lastName: googleRes.user.displayName.replace(parts[0], ""),
                    email: googleRes.user.email,
                    country: "Undefined",
                    provider: "Apple.com",
                    childs: [],
                    regDate: new Date().getTime(),
                    affiliateInfo: { affiliateId: affiliateId ? affiliateId : 0, clicks: 0, registrations: 0, freeMonthsEarned: 0 }
                }

                await this.activityService.createSelfData({ token: userObj.stsTokenManager.accessToken, parent: value }).toPromise();
                await this.sleep(3000);

                this.loaderStatus = false;
                this.router.navigate(['/cabinet']);
            } else if (adminData.result) {
                adminData = adminData.result;
                adminData.id = googleRes.user.uid;

                if (adminData['role'] == "super_administrator") {
                    localStorage.setItem('superAdmin_accessToken', userObj.stsTokenManager.accessToken);
                    localStorage.setItem('superAdmin_expirationTime', userObj.stsTokenManager.expirationTime);
                    localStorage.setItem('superAdmin_userData', JSON.stringify({ ...adminData, uid: adminData.id }));

                    await this.activityService.setSuperAdminAT({ token: userObj.stsTokenManager.accessToken }).toPromise();

                    this.router.navigate(['/admin/super/dashboard']);
                } else {
                    localStorage.setItem('quranera_admin_accessToken', userObj.stsTokenManager.accessToken);
                    localStorage.setItem('quranera_admin_expirationTime', userObj.stsTokenManager.expirationTime);
                    localStorage.setItem('quranera_admin_userData', JSON.stringify({
                        ...adminData,
                        uid: adminData.id
                    }));
                    this.router.navigate(['/admin']);
                }
            } else {
                this.loaderStatus = false;
                this.router.navigate(['/admin/cabinet']);
            }
        } else {
            localStorage.removeItem('currentUser');
            this.loaderStatus = false;
        }
    }

    login(autologin = false) {
        this.loaderStatus = true;
        this.loginFaild = false;
        this.verify = false;

        this.auth.login(this.emailFormControl.value.trim(), this.passwordFormControl.value.trim()).then(async result => {
            if (result.user) {
                localStorage.removeItem("cfal");

                let userStr = JSON.stringify(result.user);
                let userObj = JSON.parse(userStr);
                localStorage.setItem('currentUser', userStr);

                var adminData = await this.activityService.getSelfData({ collectionID: "adminUserData", token: userObj.stsTokenManager.accessToken }).toPromise();

                if (adminData.result) {
                    adminData = adminData.result;
                    adminData.id = result.user.uid;

                    if (adminData.role == "super_administrator") {
                        localStorage.setItem('superAdmin_accessToken', userObj.stsTokenManager.accessToken);
                        localStorage.setItem('superAdmin_expirationTime', userObj.stsTokenManager.expirationTime);
                        localStorage.setItem('superAdmin_userData', JSON.stringify({ ...adminData, uid: adminData.id }));

                        await this.activityService.setSuperAdminAT({ token: userObj.stsTokenManager.accessToken }).toPromise();

                        this.router.navigate(['/admin/super/dashboard']);
                    } else {
                        localStorage.setItem('quranera_admin_accessToken', userObj.stsTokenManager.accessToken);
                        localStorage.setItem('quranera_admin_expirationTime', userObj.stsTokenManager.expirationTime);
                        localStorage.setItem('quranera_admin_userData', JSON.stringify({ ...adminData, uid: adminData.id }));
                        this.router.navigate(['/admin']);
                    }
                } else {
                    var parentData = await this.activityService.getSelfData({ token: userObj.stsTokenManager.accessToken }).toPromise();

                    if (parentData.result) {
                        this.router.navigate(['/cabinet']);
                        this.loaderStatus = false;
                    } else {
                        this.loginFaild = true;
                        this.loaderStatus = false;
                    }
                }
            } else {
                localStorage.removeItem('currentUser');
                this.loaderStatus = false;
            }
        }).catch(error => {
            let passwordHash = Md5.hashStr(this.passwordFormControl.value.trim());
            let uid = this.emailFormControl.value.trim();

            if (autologin) {
                passwordHash = this.userCred.passwordHash;
                uid = this.userCred.uid;
            }

            this.auth.getUserToken({ email: uid, password: passwordHash }).subscribe(token => {
                const userData = token.userData;
                localStorage.setItem('parentEmail', token.userData.parentEmail);

                this.auth.loginWithToken(token.token).then(async data => {
                    let userStr = JSON.stringify(data.user);
                    let userObj = JSON.parse(userStr);

                    localStorage.setItem('accessToken', userObj.stsTokenManager.accessToken);
                    localStorage.setItem('expirationTime', ((new Date()).getTime() + 3600000).toString());

                    if (userData.role == 'student') {
                        try {
                            await this.assignmentService.load(uid);
                        } catch (e) { }
                    }

                    localStorage.setItem('currentUser', userStr);

                    if (userData) {
                        //if (userData.data()['HQ'] == "Brainy Bunch") {
                        //    this.loginFaild = true;
                        //    this.loaderStatus = false;

                        //    return;
                        //}
                        this.user = userData;

                        if (this.user.sessions === undefined) {
                            this.user.sessions = [];
                        }
                        if (!this.signed) {
                            localStorage.setItem("cfal", JSON.stringify({ uid, passwordHash }));
                            this.signed = true;
                        }

                        this.loaderStatus = false;
                        this.appendScriptToBody(token.userData.parentEmail);

                        if (this.user.sessionDurations && this.user.sessionDurations.length > 0) {
                            this.user.sessionDurations = this.user.sessionDurations.sort((a, b) => b.date - a.date);

                            var activityData = this.user.sessionDurations[0];

                            if (activityData.quiz) {
                                this.zone.run(() => {
                                    this.router.navigate(['/game/' + this.quizzes[activityData.activityId]]);
                                });
                            } else {
                                this.zone.run(() => {
                                    this.router.navigate(['/game/activity-list/' + activityData.activityId]);
                                });
                            }
                        } else {
                            this.router.navigate(['/game']);
                        }
                    } else {
                        localStorage.removeItem("cfal");
                        this.loginFaild = true;
                        this.loaderStatus = false;
                    }
                }).catch(error => {
                    this.loginFaild = true;
                    this.loaderStatus = false;
                });
            }, error => {
                this.loginFaild = true;
                this.loaderStatus = false;
            });
        });
    }

    loginViaQRCode(qrData) {
        this.auth.getUserTokenViaID({ qrData }).subscribe(token => {
            const userData = token.userData;
            this.auth.loginWithToken(token.token).then(async data => {
                if (userData.role == 'student') {
                    try {
                        await this.assignmentService.load(data.user.uid);
                    } catch (e) { }
                }

                localStorage.setItem('currentUser', JSON.stringify(data.user));

                if (userData) {
                    //if (userData.data()['HQ'] == "Brainy Bunch") {
                    //    this.loginFaild = true;
                    //    this.loaderStatus = false;

                    //    return;
                    //}
                    this.user = userData;

                    if (this.user.sessions === undefined) {
                        this.user.sessions = [];
                    }
                    if (!this.signed) {
                        this.user.sessions.push({ date: Math.floor(Date.now() / 1000) })
                        this.signed = true;
                    }

                    this.loaderStatus = false;
                    this.appendScriptToBody(token.userData.parentEmail);

                    if (this.user.sessionDurations && this.user.sessionDurations.length > 0) {
                        this.user.sessionDurations = this.user.sessionDurations.sort((a, b) => b.date - a.date);

                        var activityData = this.user.sessionDurations[0];

                        if (activityData.quiz) {
                            this.zone.run(() => {
                                this.router.navigate(['/game/' + this.quizzes[activityData.activityId]]);
                            });
                        } else {
                            this.zone.run(() => {
                                this.router.navigate(['/game/activity-list/' + activityData.activityId]);
                            });
                        }
                    } else {
                        this.router.navigate(['/game']);
                    }
                } else {
                    localStorage.removeItem("cfal");
                    this.loginFaild = true;
                    this.loaderStatus = false;
                }
            }).catch(error => {
                this.loginFaild = true;
                this.loaderStatus = false;
            });
        }, error => {
            this.loginFaild = true;
            this.loaderStatus = false;
        });
    }

    sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }
}
