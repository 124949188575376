import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContinentsComponent } from './continents/continents.component';
import { RouterModule } from '@angular/router';
import { GameState } from './game.route';
import { GameComponent } from './game.component';
import { Zone1Component } from './zone-1/zone-1.component';
import { ActivityListComponent } from './activity-list/activity-list.component';
import { QuestionAndAnswerComponent } from './activities/q-a/q-a.component';
import { VideoActivityComponent } from './activities/video/video.component';
import { FirebaseModule } from '../core/firebase/firebase.module';
import { DragDropActivityComponent } from './activities/drag-drop/drag-drop.component';
import { ArrayRandomSortPipe } from '../core/randomSort.pipe';
import { ReadingActivityComponent } from './activities/reading/reading.component';
import { DotToDotActivityComponent } from './activities/dot-to-dot/dot-to-dot.component';
import { BoardComponent } from './activities/dot-to-dot/board.component';
import { FindTheLetterActivityComponent } from './activities/find-the-letter/find-the-letter.component';
import { MatchTheWordActivityComponent } from './activities/match-the-word/match-the-word.component';
import { QuizActivityComponent } from './activities/quiz/quiz.component';
import { ProfileComponent } from './profile/profile.component';
import { SetAvatarComponent } from './set-avatar/set-avatar.component';
import { AwardsComponent } from './awards/awards.component';
import { StoriesComponent } from './stories/stories.component';
import { SongsComponent } from './songs/songs.component';
import { BooksComponent } from './books/books.component';
import { WelcomeActivityComponent } from './activities/welcome/welcome.component';
import { GalleryComponent } from './gallery/gallery.component';
import { ArrayRandomIndexPipe } from '../core/randomIndex.pipe';
import { SoundSearchComponent } from './activities/sound-search/sound-search.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { LetterSongActivityComponent } from './activities/letter-song/letter-song.component';
import { Zone2Component } from './zone-2/zone-2.component';
import { Zone3Component } from './zone-3/zone-3.component';
import { Zone4Component } from './zone-4/zone-4.component';
import { InnerLogInComponent } from './inner-log-in/inner-log-in.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Zone5Component } from './zone5/zone5.component';
import { SoundHiveComponent } from './activities/sound-hive/sound-hive.component';
import { LetterGridComponent } from './activities/letter-grid/letter-grid.component';
import { LetterCloudComponent } from './activities/letter-cloud/letter-cloud.component';
import { DragNDropComponent } from './activities/drag-n-drop/drag-n-drop.component';
import { Zone6Component } from './zone6/zone6.component';
import { Zone7Component } from './zone7/zone7.component';
import { CrossTheBridgeComponent } from './activities/cross-the-bridge/cross-the-bridge.component';
import { LetterBasketComponent } from './activities/letter-basket/letter-basket.component';
import { CarRepairComponent } from './activities/car-repair/car-repair.component';
import { CatchTheCrabComponent } from './activities/catch-the-crab/catch-the-crab.component';
import { IceBlocksComponent } from './activities/ice-blocks/ice-blocks.component';
import { ShootingHoopsComponent } from './activities/shooting-hoops/shooting-hoops.component';
import { PopTheBubblesComponent } from './activities/pop-the-bubbles/pop-the-bubbles.component';
import { Africa1Component } from './africa-1/africa-1.component';
import { Africa2Component } from './africa-2/africa-2.component';
import { Africa3Component } from './africa-3/africa-3.component';
import { SoundPadsComponent } from './activities/sound-pads/sound-pads.component';
import { SoundBallsComponent } from './activities/sound-balls/sound-balls.component';
import { LoadTrainComponent } from './activities/load-train/load-train.component';
import { LetterBubbleComponent } from './activities/letter-bubble/letter-bubble.component';
import { RocketLaunchComponent } from './activities/rocket-launch/rocket-launch.component';
import { ClimbWallComponent } from './activities/climb-wall/climb-wall.component';
import { TruckRaceComponent } from './activities/truck-race/truck-race.component';
import { DoughPressComponent } from './activities/dough-press/dough-press.component';
import { DemolishBuildingComponent } from './activities/demolish-building/demolish-building.component';
import { VideosComponent } from './videos/videos.component';
import { IqraTimeActivityComponent } from './activities/iqra-time/iqraTime.component';
import { AssignmentComponent } from './assignment/assignment.component';
import { MaterialModule } from '../admin/plugin/material.module';
import { ReciteVerseComponent } from './activities/reacite-the-verse/recite.verse';
import { SouthAmerica1Component } from './south-america-1/south-america-1.component';
import { SouthAmerica2Component } from './south-america-2/south-america-2.component';
import { SouthAmerica3Component } from './south-america-3/south-america-3.component';
import { AeroPlaneComponent } from './activities/aeroplane/aeroplane.component';
import { BirdGameComponent } from './activities/birdGame/bird.game.component';
import { FarmGameComponent } from './activities/farm/farm.component';
import { JumpingKangarooComponent } from './activities/jumping-kangaroo/jumping-kangaroo.component';
import { MeteorsGameComponent } from './activities/meteors-game/meteors-game.component';
import { ToTheAirComponent } from './activities/to-the-air/to-the-air.component';
import { TruckGameComponent } from './activities/truck-game/truck.game.component';
import { FactoryGameComponent } from './activities/factoryGame/factory.game.component';
import { IceBoardComponent } from './activities/ice-board/ice-board.component';
import { StackWoodComponent } from './activities/stack-wood/stack-wood.component';
import { SubmarineGameComponent } from './activities/submarine/submarine.component';
import { CastleGameComponent } from './activities/castle/castle.component';
import { MosqueCVComponent } from './activities/mosque-complete-verse/mosqueCV.component';
import { FindTheWayComponent } from './activities/find-the-way/fint-the-way.component';
import { SlimeSplatComponent } from './activities/slime-splat/slime-splat.component';

@NgModule({
    declarations: [
        ContinentsComponent,
        GameComponent,
        Zone1Component,
        Zone2Component,
        Zone3Component,
        Zone4Component,
        Africa1Component,
        Africa2Component,
        Africa3Component,
        SouthAmerica1Component,
        SouthAmerica2Component,
        SouthAmerica3Component,
        ActivityListComponent,
        QuestionAndAnswerComponent,
        VideoActivityComponent,
        DragDropActivityComponent,
        ArrayRandomSortPipe,
        ArrayRandomIndexPipe,
        ReadingActivityComponent,
        DotToDotActivityComponent,
        BoardComponent,
        FindTheLetterActivityComponent,
        MatchTheWordActivityComponent,
        QuizActivityComponent,
        WelcomeActivityComponent,
        ProfileComponent,
        SetAvatarComponent,
        AwardsComponent,
        AssignmentComponent,
        StoriesComponent,
        VideosComponent,
        SongsComponent,
        BooksComponent,
        GalleryComponent,
        SoundSearchComponent,
        LetterBubbleComponent,
        LetterSongActivityComponent,
        InnerLogInComponent,
        Zone5Component,
        SoundHiveComponent,
        LetterGridComponent,
        LetterCloudComponent,
        ClimbWallComponent,
        DragNDropComponent,
        Zone6Component,
        Zone7Component,
        CrossTheBridgeComponent,
        LetterBasketComponent,
        SoundPadsComponent,
        SoundBallsComponent,
        CarRepairComponent,
        TruckRaceComponent,
        CatchTheCrabComponent,
        DemolishBuildingComponent,
        IceBlocksComponent,
        ShootingHoopsComponent,
        PopTheBubblesComponent,
        RocketLaunchComponent,
        LoadTrainComponent,
        DoughPressComponent,
        IqraTimeActivityComponent,
        ReciteVerseComponent,
        AeroPlaneComponent,
        BirdGameComponent,
        FarmGameComponent,
        JumpingKangarooComponent,
        MeteorsGameComponent,
        ToTheAirComponent,
        TruckGameComponent,
        FactoryGameComponent,
        IceBoardComponent,
        StackWoodComponent,
        SubmarineGameComponent,
        CastleGameComponent,
        MosqueCVComponent,
        FindTheWayComponent,
        SlimeSplatComponent
    ],
    imports: [
        CommonModule,
        FirebaseModule,
        RouterModule.forChild(GameState),
        DragDropModule,
        FormsModule,
        ReactiveFormsModule,
        MaterialModule
    ],
    entryComponents: [
        BoardComponent
    ]
})
export class GameModule { }
